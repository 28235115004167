import { Grid, Section } from "@intility/bifrost-react";
import { Link } from "react-router-dom";
import manageClustersCli from "~/assets/images/home/manage-clusters-cli.png";
import manageClustersPortal from "~/assets/images/home/manage-clusters-portal.png";
import whatIsIcp from "~/assets/images/home/what-is-icp.png";
import styles from "./guidesSection.module.css";

interface GuidesSectionProps {
  className?: string;
}

const GuidesSection = ({ className }: GuidesSectionProps) => {
  return (
    <Section className={className}>
      <Link to="/helpcenter?tab=guides" className="bf-neutral-link">
        <Section.Header arrow>
          <span className="bf-neutral-link-text">Guides</span>
        </Section.Header>
      </Link>

      <Section.Content>
        <Grid small={3}>
          <a
            href="https://publish.intility.com/en-us/articles/51ec0d96-220b-4e66-402b-08dc346b24fd"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.guide}>
              <img src={manageClustersCli} alt="managing clusters with cli" />
              <span className={styles.guideLabel}>
                Manage clusters with CLI (icpctl)
              </span>
            </div>
          </a>
          <a
            href="https://publish.intility.com/en-us/articles/7f9cc811-02f7-47f0-39d8-08dc85ed70aa"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.guide}>
              <img
                src={manageClustersPortal}
                alt="managing clusters with portal"
              />
              <span className={styles.guideLabel}>
                Manage clusters using the portal
              </span>
            </div>
          </a>
          <a
            href="https://publish.intility.com/nb-no/articles/d6ffbd1d-b121-431c-460f-08dc846ac68b"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.guide}>
              <img src={whatIsIcp} alt="what is the developer platform" />
              <span className={styles.guideLabel}>
                What is the Developer Platform?
              </span>
            </div>
          </a>
        </Grid>
      </Section.Content>
    </Section>
  );
};

export default GuidesSection;
