export type SortType<T> = {
  key: keyof T;
  direction: "asc" | "desc" | "none";
};

const sortByKey =
  <T extends object>(sort: SortType<T>) =>
  (a: T, b: T) => {
    const aValue = a[sort.key];
    const bValue = b[sort.key];
    if (typeof aValue === "number" && typeof bValue === "number") {
      if (sort.direction === "asc") {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    } else if (typeof aValue === "boolean" && typeof bValue === "boolean") {
      if (sort.direction === "asc") {
        return aValue === bValue ? 0 : aValue ? -1 : 1;
      } else {
        return aValue === bValue ? 0 : aValue ? 1 : -1;
      }
    } else if (typeof aValue === "string" && typeof bValue === "string") {
      if (sort.direction === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    }
    return 0;
  };

export default sortByKey;
