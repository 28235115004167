import { Button, Icon, Section } from "@intility/bifrost-react";
import styles from "./roleSection.module.css";
import womanPeace from "~/assets/woman-peace.svg";
import manGreeting from "~/assets/man-greeting.svg";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import useRole from "~/hooks/useRole";
import { Link } from "react-router-dom";

interface RoleSectionProps {
  className?: string;
}

const RoleSection = ({ className }: RoleSectionProps) => {
  const role = useRole();

  return (
    <Section className={className}>
      <div className={styles.roleCard}>
        {role.isAdmin ? (
          <>
            <div className={styles.roleIllustration}>
              <img src={womanPeace} alt="woman making peace symbol" />
            </div>
            <div className={styles.roleContent}>
              <span className={styles.roleLabel}>Role</span>
              <h5 className="bf-h5">Administrator</h5>
              <span>
                You can view and edit all clusters, as well as read guides
              </span>
              <Link className={styles.roleButtonAnchor} to="/clusters">
                <Button
                  className={styles.roleButton}
                  state="neutral"
                  pill
                  variant="filled"
                >
                  Administrate clusters
                  <Icon icon={faArrowRight} />
                </Button>
              </Link>
            </div>
          </>
        ) : role.isReader ? (
          <>
            <div className={styles.roleIllustration}>
              <img src={manGreeting} alt="man greeting" />
            </div>
            <div className={styles.roleContent}>
              <span className={styles.roleLabel}>Role</span>
              <h5 className="bf-h5">Read only</h5>
              <span>You can only view clusters and read guides</span>
              <a
                className={styles.roleButtonAnchor}
                target="_blank"
                rel="noreferrer"
                href="https://my.intility.com/nb-no/apps?term=intility+container+platform"
              >
                <Button
                  className={styles.roleButton}
                  state="neutral"
                  pill
                  variant="filled"
                >
                  Request admin access
                  <Icon icon={faArrowRight} />
                </Button>
              </a>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </Section>
  );
};

export default RoleSection;
