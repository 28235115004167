import styles from "./overviewCard.module.css";

const OverviewCard = ({
  dataValue,
  dataLabel,
  cardGraphics,
  centerGraphics = true,
  isHighlighted = false,
}: {
  dataValue: number;
  dataLabel: string;
  cardGraphics: React.ReactNode;
  centerGraphics?: boolean;
  isHighlighted?: boolean;
}) => {
  return (
    <div
      className={`${styles.overviewCard} ${isHighlighted && styles.cardHighlighted}`}
    >
      <div className={styles.cardData}>
        <h2
          className={`bf-h2 ${styles.dataValue} ${isHighlighted && styles.valueHighlighted}`}
        >
          {dataValue}
        </h2>
        <span
          className={`${styles.dataLabel} ${isHighlighted && styles.labelHighlighted}`}
        >
          {dataLabel}
        </span>
      </div>
      <div className={`${centerGraphics && styles.centered}`}>
        {cardGraphics}
      </div>
    </div>
  );
};

export default OverviewCard;
