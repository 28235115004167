import useSWRImmutable from "swr/immutable";
import { authorizedFetch } from "~/auth";

type CustomError = {
  statusCode: number;
  statusText: string;
  resource: string;
};

const useProfilePicture = () => {
  const blobFetcher = (url: string) =>
    authorizedFetch(url)
      .then((r) => {
        if (!r.ok) {
          const error: CustomError = {
            statusCode: r.status,
            statusText: "An error occurred while fetching the data.",
            resource: url,
          };
          throw error;
        }
        return r.blob();
      })
      .then(URL.createObjectURL);

  return useSWRImmutable<string>(
    "https://graph.microsoft.com/v1.0/me/photos/120x120/$value",
    blobFetcher,
  );
};

export default useProfilePicture;
