import {
  Badge,
  Grid,
  Icon,
  Input,
  Pagination,
  Skeleton,
  Table,
  useBreakpoint,
} from "@intility/bifrost-react";
import styles from "./clusterTable.module.css";
import { faTrashCan, faSearch } from "@fortawesome/pro-regular-svg-icons";
import DeleteModal from "./DeleteModal";
import { useState } from "react";
import { useClusters } from "~/api/useClusterApi";
import sortByKey, { SortType } from "~/utils/sortByKey";
import CallToAction from "./CallToAction";
import useRole from "~/hooks/useRole";

type ClusterTableData = {
  name: string;
  consoleUrl: string;
  status: boolean;
};

const ClusterTable = () => {
  const role = useRole();
  const { data: clusters, isLoading } = useClusters();

  const clustersData: ClusterTableData[] | undefined = clusters?.map(
    (cluster) => ({
      name: cluster.name,
      consoleUrl: cluster.consoleUrl,
      status: cluster.status.ready.status ? true : false,
    }),
  );

  const [clusterFilter, setClusterFilter] = useState("");
  const [sort, setSort] = useState<SortType<ClusterTableData>>({
    key: "name",
    direction: "asc",
  });
  const [page, setPage] = useState(1);
  const pageSize = 6;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentClusterName, setCurrentClusterName] = useState("");
  const toMedium = useBreakpoint(null, "medium");

  function filterClusters() {
    if (!clustersData) {
      return [];
    }
    return clustersData.filter((cluster) =>
      cluster.name.toLowerCase().includes(clusterFilter.toLowerCase()),
    );
  }

  const onSortChange = (key: keyof ClusterTableData) => () => {
    setSort((oldSort) => {
      if (oldSort.key === key && oldSort.direction === "asc") {
        return { key, direction: "desc" };
      } else {
        return { key, direction: "asc" };
      }
    });
  };

  const getSortProp = (key: keyof ClusterTableData) =>
    sort.key === key ? sort.direction : "none";

  const sortedData = filterClusters()
    .sort(sortByKey(sort))
    .slice(page * pageSize - 6, page * pageSize);

  return (
    <>
      {clusters?.length === 0 && !isLoading ? (
        <CallToAction />
      ) : (
        <>
          <div className={styles.container}>
            <h5 className={`bf-h5 ${styles.title}`}>All clusters</h5>
            <div className={styles.searchInputContainer}>
              <Input
                className={styles.searchInput}
                placeholder="Look up cluster"
                label={undefined}
                icon={faSearch}
                clearable
                rightIcon
                value={clusterFilter}
                onChange={(e) => setClusterFilter(e.target.value)}
              />
            </div>

            <Table noBorder className={styles.table}>
              <Table.Header>
                <Table.Row>
                  {toMedium && <Table.HeaderCell />}
                  <Table.HeaderCell
                    className={styles.headerCell}
                    sorting={getSortProp("name")}
                    onClick={onSortChange("name")}
                  >
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className={`from-medium ${styles.headerCell}`}
                    sorting={getSortProp("consoleUrl")}
                    onClick={onSortChange("consoleUrl")}
                  >
                    Console url
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className={styles.headerCell}
                    sorting={getSortProp("status")}
                    onClick={onSortChange("status")}
                  >
                    Status
                  </Table.HeaderCell>

                  {/* Rubbish bin Column */}
                  <Table.HeaderCell className={styles.headerCell} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isLoading && (
                  <Skeleton repeat={5}>
                    <Table.Row>
                      <Skeleton repeat={3}>
                        <Table.Cell>
                          <Skeleton.Text />
                        </Table.Cell>
                      </Skeleton>
                    </Table.Row>
                  </Skeleton>
                )}
                {sortedData.map((cluster) => (
                  <Table.Row
                    key={cluster.name}
                    limitExpandClick
                    content={
                      toMedium ? (
                        <Grid
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "24px",
                          }}
                        >
                          <span style={{ width: "50px", marginRight: "8px" }}>
                            Console url
                          </span>
                          <a
                            className={`bf-link ${styles.clusterUrl}`}
                            href={cluster.consoleUrl}
                          >
                            {cluster.consoleUrl}
                          </a>
                        </Grid>
                      ) : (
                        ""
                      )
                    }
                  >
                    <Table.Cell style={{ flex: 1 }}>{cluster.name}</Table.Cell>
                    <Table.Cell className={`from-medium`}>
                      <a
                        className={`bf-link ${styles.clusterUrl}`}
                        href={cluster.consoleUrl}
                      >
                        {cluster.consoleUrl}
                      </a>
                    </Table.Cell>
                    <Table.Cell className={styles.statusCell}>
                      <Badge
                        pill
                        state={cluster.status !== true ? "alert" : "success"}
                      >
                        <span className="bf-satoshi">
                          {cluster.status ? "Ready" : "Not ready"}
                        </span>
                      </Badge>
                    </Table.Cell>
                    <Table.Cell
                      className={
                        role.isAdmin ? styles.iconCell : styles.iconCellHidden
                      }
                    >
                      <div className={styles.trashCanIconBox}>
                        <Icon
                          icon={faTrashCan}
                          onClick={() => {
                            setIsDeleteModalOpen(true);
                            setCurrentClusterName(cluster.name);
                          }}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>

          <DeleteModal
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            clusterName={currentClusterName}
          />
          <Pagination
            onChange={setPage}
            currentPage={page}
            totalPages={Math.ceil(filterClusters().length / pageSize)}
          />
        </>
      )}
    </>
  );
};

export default ClusterTable;
