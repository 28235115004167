import { authorizedFetch } from "~/auth/authorizedFetch";

const baseAddress = import.meta.env["VITE_BACKEND_BASE"];
export const swrConfig = {
  fetcher: (path: string) =>
    authorizedFetch(`${baseAddress}/api/v1/${path}`).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        return response.json();
      }
    }),
};
