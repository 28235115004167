import { wrapCreateBrowserRouter } from "@sentry/react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";
import Home from "./pages/home/Home";
import Navigation from "./components/Navigation";
import Profile from "./components/Profile";
import ClusterListPage from "./pages/cluster/clusterListPage/ClusterListPage";
import HelpCenterPage from "./pages/helpCenter/HelpCenterPage";
import NewClusterPage from "./pages/cluster/newClusterPage/NewClusterPage";
import { ErrorPage } from "./pages/error/ErrorPage";

/**
 * Sentry integration
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

/**
 * The applications router, using react-router data APIs
 * @see https://reactrouter.com/en/main/routers/create-browser-router
 */
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={<ErrorPage />}
      element={
        <Navigation>
          <Outlet />
        </Navigation>
      }
    >
      <Route path="*" element={<ErrorPage code={404} />} />

      <Route path="/" element={<Home />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/clusters" element={<ClusterListPage />} />
      <Route path="/clusters/new" element={<NewClusterPage />} />
      <Route path="/helpcenter" element={<HelpCenterPage />} />
    </Route>,
  ),
);

export { router };
