import {
  Modal,
  Grid,
  Inline,
  Button,
  Icon,
  Input,
  Spinner,
  useFloatingMessage,
} from "@intility/bifrost-react";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";

import styles from "./deleteModal.module.css";
import { useState } from "react";
import { authorizedFetch } from "~/auth";
import useSWR from "swr";
import { Cluster } from "~/types";

const baseAddress = import.meta.env["VITE_BACKEND_BASE"];

const DeleteModal = ({
  isOpen,
  setIsOpen,
  clusterName,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  clusterName: string;
}) => {
  const [inputString, setInputString] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const { mutate } = useSWR<Cluster[]>("clusters");

  const { showFloatingMessage } = useFloatingMessage();

  const handleDelete = async () => {
    setIsFetching(true);

    await authorizedFetch(baseAddress + `/api/v1/clusters/${clusterName}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (!response.ok) {
        showFloatingMessage(
          <div className={styles.floatingMessage}>
            <span className={styles.floatingMessageTitle}>
              Cluster not deleted
            </span>
            <span>
              Cluster{" "}
              <span
                className={styles.floatingMessageClusterName}
                style={{ fontWeight: "900" }}
              >
                {clusterName}
              </span>{" "}
              could not be deleted. Please try again later.
            </span>
          </div>,
          { state: "warning", noIcon: true },
        );
      } else {
        showFloatingMessage(
          <div className={styles.floatingMessage}>
            <span className={styles.floatingMessageTitle}>Cluster deleted</span>
            <span>
              Cluster{" "}
              <span
                className={styles.floatingMessageClusterName}
                style={{ fontWeight: "900" }}
              >
                {clusterName}
              </span>{" "}
              has been successfully deleted.
            </span>
          </div>,
          { state: "success", noIcon: true },
        );
      }
    });

    setIsFetching(false);
    closeModal();
    await mutate();
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsFetching(false);
    setInputString("");
  };

  return (
    <Modal
      className={styles.modal}
      onRequestClose={() => closeModal()}
      header={
        <h5 className="bf-h5">
          <Icon icon={faWarning} className={styles.warningIcon} />
          Delete cluster
        </h5>
      }
      isOpen={isOpen}
    >
      <Grid>
        <span>
          Are you sure you want to delete{" "}
          <span className={styles.clusterName}>{clusterName}</span>?
        </span>
        <Input
          className={styles.confirmationInput}
          label="Write cluster name to delete"
          placeholder="Cluster name"
          value={inputString}
          onChange={(e) => setInputString(e.target.value)}
        />
        <Inline>
          <Inline.Separator />
          <Button
            className={styles.modalButton}
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            className={styles.modalButton}
            variant="filled"
            state="alert"
            disabled={inputString !== clusterName || isFetching}
            onClick={() => {
              handleDelete();
            }}
          >
            {isFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "12px",
                }}
              >
                <span>Deleting</span>
                <Spinner size={14} />
              </div>
            ) : (
              "Delete"
            )}
          </Button>
        </Inline>
      </Grid>
    </Modal>
  );
};

export default DeleteModal;
