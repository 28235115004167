// https://bifrost.intility.com/#/Welcome%20to%20Bifrost/Get%20started/Installation
import "@intility/bifrost-react/bifrost-app.css";

import "~/styles/global.css";
import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { instance } from "./auth";
import { initializeSentry } from "./utils/initializeSentry";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { InteractionType } from "@azure/msal-browser";
import { SWRConfig } from "swr";
import { swrConfig } from "./api/swrConfig";
import { FloatingMessage } from "@intility/bifrost-react";
import { initializeInstrumentations } from "./utils/registerInstrumentations";

const rootElement = document.getElementById("root")!;

// https://bifrost.intility.com/#/Components/Interactive/Modal
// Uncomment next line if using Modal from Bifrost
// Modal.setAppElement(rootElement);

const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <MsalProvider instance={instance}>
      <SWRConfig value={swrConfig}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{
            scopes: [
              "User.Read",
              "api://cd5aff56-575c-4e7e-b68e-3f67fa42eb31/user_impersonation",
            ],
          }}
        >
          <FloatingMessage timeout={5000}>
            <RouterProvider router={router} />
          </FloatingMessage>
        </MsalAuthenticationTemplate>
      </SWRConfig>
    </MsalProvider>
  </StrictMode>,
);

initializeSentry();
initializeInstrumentations();
