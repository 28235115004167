import { Badge, Grid, Icon } from "@intility/bifrost-react";
import styles from "./summaryStep.module.css";
import womanHoldingDark from "~/assets/woman-holding-dark.svg";
import { NewClusterForm, NodeSpecPreset } from "~/types";
import { isValidClusterName } from "~/utils/validations";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";

const SummaryStep = ({ formData }: { formData: NewClusterForm }) => {
  return (
    <div className={styles.content}>
      <h4 className="bf-h4">Summary</h4>
      <img
        src={womanHoldingDark}
        className={styles.illustration}
        alt="illustration of a woman pointing at the values"
      />
      <Grid cols={2} gap={24}>
        <div className={styles.fieldBox}>
          <Badge
            state={
              isValidClusterName(formData.clusterName) ? "neutral" : "warning"
            }
            pill
            className={styles.fieldBoxBadge}
          >
            {isValidClusterName(formData.clusterName) ? (
              formData.clusterName + "-" + formData.clusterNameSuffix
            ) : (
              <span>
                <Icon icon={faWarning} marginRight />
                Missing information
              </span>
            )}
          </Badge>
          <span className="bf-label-description">Cluster name</span>
        </div>
        <div className={styles.fieldBox}>
          <Badge state="neutral" pill className={styles.fieldBoxBadge}>
            {formData.nodeCount}
          </Badge>
          <span className="bf-label-description">Node count</span>
        </div>
        <div className={styles.fieldBox}>
          <Badge state="neutral" pill className={styles.fieldBoxBadge}>
            {formData.preset === NodeSpecPreset.Minimal
              ? "2"
              : formData.preset === NodeSpecPreset.Balanced
                ? "4"
                : formData.preset === NodeSpecPreset.Performance
                  ? "6"
                  : ""}
          </Badge>
          <span className="bf-label-description">CPU</span>
        </div>
        <div className={styles.fieldBox}>
          <Badge state="neutral" pill className={styles.fieldBoxBadge}>
            {formData.preset === NodeSpecPreset.Minimal
              ? "8"
              : formData.preset === NodeSpecPreset.Balanced
                ? "16"
                : formData.preset === NodeSpecPreset.Performance
                  ? "24"
                  : ""}
          </Badge>
          <span className="bf-label-description">GB RAM</span>
        </div>
      </Grid>
    </div>
  );
};

export default SummaryStep;
