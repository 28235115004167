import { Button, Icon, Spinner } from "@intility/bifrost-react";
import styles from "./createClusterFooter.module.css";
import { NewClusterStep } from "~/types";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons";

const CreateClusterFooter = ({
  steps,
  activeStep,
  setActiveStep,
  cancelButtonOnClick,
  createButtonOnClick,
  createButtonDisabled,
  createButtonFetching,
}: {
  steps: NewClusterStep[];
  activeStep: NewClusterStep;
  setActiveStep: (activeStep: NewClusterStep) => void;
  cancelButtonOnClick: () => void;
  createButtonOnClick: () => void;
  createButtonDisabled: boolean;
  createButtonFetching: boolean;
}) => {
  const isFirstStep = activeStep.id === steps[0].id;
  const isLastStep = activeStep.id === steps[steps.length - 1].id;
  const previousStep = steps.filter((step) => step.id === activeStep.id - 1)[0];
  const nextStep = steps.filter((step) => step.id === activeStep.id + 1)[0];

  return (
    <div className={styles.footer}>
      <Button variant="flat" state="neutral" onClick={cancelButtonOnClick}>
        Cancel
      </Button>
      <div className={styles.footerStepButtonSection}>
        <Button
          className={styles.stepButton}
          state={isFirstStep ? "inactive" : "neutral"}
          disabled={isFirstStep ? true : undefined}
          onClick={() => {
            setActiveStep(previousStep);
          }}
        >
          <Icon icon={faArrowLeft} />
          Previous
        </Button>
        {isLastStep ? (
          <Button
            className={styles.stepButton}
            state={createButtonDisabled ? "inactive" : "neutral"}
            variant="filled"
            disabled={createButtonDisabled}
            onClick={createButtonOnClick}
          >
            {createButtonFetching ? (
              <>
                Creating
                <Spinner size={14} />
              </>
            ) : (
              <>
                Create
                <Icon icon={faCheck} />
              </>
            )}
          </Button>
        ) : (
          <Button
            className={styles.stepButton}
            state={isLastStep ? "inactive" : "neutral"}
            disabled={isLastStep ? true : undefined}
            onClick={() => {
              setActiveStep(nextStep);
            }}
          >
            Next
            <Icon icon={faArrowRight} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateClusterFooter;
