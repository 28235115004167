import { Icon, Table } from "@intility/bifrost-react";
import styles from "./inDeploymentTable.module.css";
import { faCircleDown, faCircleUp } from "@fortawesome/pro-solid-svg-icons";
import { useClusters } from "~/api/useClusterApi";

const InDeploymentTable = () => {
  const { data: clusters } = useClusters();

  const clustersInDeployment = clusters?.filter(
    (cluster) => cluster.status.deployment.active === true,
  );

  return (
    clustersInDeployment &&
    clustersInDeployment.length > 0 && (
      <>
        <div className={styles.container}>
          <div className={styles.headerSection}>
            <h5 className={`bf-h5 ${styles.title}`}>In deployment</h5>
            <div className={styles.deploymentStatus}>
              <span>
                <Icon
                  icon={faCircleUp}
                  className={styles.deploymentStateIcon}
                  color="var(--bfc-base-c-success)"
                />
                {clustersInDeployment.filter(
                  (c) => c.status.deployment.active === true,
                ).length || 0}{" "}
                deploying
              </span>
              <span>
                <Icon
                  icon={faCircleDown}
                  className={styles.deploymentStateIcon}
                  color="var(--bfc-base-c-alert)"
                />
                {clustersInDeployment.filter(
                  (c) => c.status.deployment.failed === true,
                ).length || 0}{" "}
                failed
              </span>
            </div>
          </div>

          <Table className={styles.table} noBorder>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={styles.headerCell} />
                <Table.HeaderCell className={styles.headerCell} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {clustersInDeployment.map((cluster) => (
                <Table.Row key={cluster.name} className={styles.tableRow}>
                  <Table.Cell
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    {/* <div className={styles.verticalLine} /> */}
                    <div
                      className={
                        cluster.status.deployment.failed === true
                          ? styles.verticalLineAlert
                          : styles.verticalLineSuccess
                      }
                    />
                    {cluster.name}
                  </Table.Cell>
                  <Table.Cell style={{ width: "16px" }}>
                    <Icon
                      icon={
                        cluster.status.deployment.failed === true
                          ? faCircleDown
                          : faCircleUp
                      }
                      className={styles.deploymentStateIcon}
                      color={
                        cluster.status.deployment.failed === true
                          ? "var(--bfc-base-c-alert)"
                          : "var(--bfc-base-c-success)"
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </>
    )
  );
};

export default InDeploymentTable;
