import { faGrid2, faList, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Input } from "@intility/bifrost-react";
import { useState } from "react";
import manageClusterCliImg from "~/assets/images/guides/manage-clusters-cli.png";
import manageClusterPortalImg from "~/assets/images/guides/manage-clusters-portal.png";
import whoAreWeImg from "~/assets/images/guides/who-are-we.png";
import { Guides } from "./components/Guides";
import styles from "./guidesPage.module.css";
import { cn } from "~/utils/clsx";

export type DisplayMode = "list" | "tile";

const GuidesPage = () => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>("list");
  const [searchValue, setSearchValue] = useState("");

  const filteredGuides = guides.filter((g) =>
    g.title.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <div className={styles.content}>
      <div className={styles.introBanner}>
        <div className={styles.textSection}>
          <h5 className="bf-h5">Hello! 👋</h5>
          <span>
            Here you will find articles and guides, and hopefully get answers to
            any questions you may have. Use the search field to find exactly
            what you are looking for, and choose between list and tile view.
          </span>
        </div>
        <div className={styles.inputSection}>
          <Input
            className={styles.searchInput}
            label="search"
            hideLabel
            clearable
            placeholder="Search"
            icon={faSearch}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            iconButton
            rightIcon
          />
          <div className={styles.displayOptions}>
            <Button small variant="flat" onClick={() => setDisplayMode("list")}>
              <Icon
                icon={faList}
                className={cn(styles.displayIcon, {
                  [styles.active]: displayMode === "list",
                })}
              />
            </Button>

            <div className={styles.verticalDivider} />

            <Button small variant="flat" onClick={() => setDisplayMode("tile")}>
              <Icon
                icon={faGrid2}
                className={cn(styles.displayIcon, {
                  [styles.active]: displayMode === "tile",
                })}
              />
            </Button>
          </div>
        </div>
      </div>

      <Guides displayMode={displayMode} guides={filteredGuides} />
    </div>
  );
};

export default GuidesPage;

const guides = [
  {
    imgSrc: whoAreWeImg,
    imgAlt: "Two employees sitting at their desks",
    title: "Who are we?",
    ingress:
      "Our goal is to help you spend more time on development and less time on everything else. We take care of the technical stuff, so you can focus on the creative.",
    href: "https://article.intility.com/en-us/d6ffbd1d-b121-431c-460f-08dc846ac68b",
  },
  {
    imgSrc: manageClusterPortalImg,
    imgAlt: "Screenshot of the cluster creation portal user interface",
    title: "Manage clusters in the portal",
    ingress:
      "This guide will help you manage your cluster effectively using the portal",
    href: "https://article.intility.com/en-us/7f9cc811-02f7-47f0-39d8-08dc85ed70aa",
  },
  {
    imgSrc: manageClusterCliImg,
    imgAlt: "Screenshot of the icpctl CLI tool",
    title: "Manage clusters with the CLI",
    ingress:
      "This guide will help you understand how to create, list, and delete clusters using the command-line tool and icpctl.",
    href: "https://article.intility.com/en-us/51ec0d96-220b-4e66-402b-08dc346b24fd",
  },
];
