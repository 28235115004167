import { Configuration } from "@azure/msal-browser";

/**
 * MSAL config for the PublicClientApplication
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const config: Configuration = {
  auth: {
    clientId: "27f5ab79-28cb-4824-b603-4b0795b8985e",
    authority: "https://login.microsoftonline.com/organizations",
    redirectUri: window.location.origin,
  },
};

export { config };
