import { Button, Icon } from "@intility/bifrost-react";
import styles from "./supportCard.module.css";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { cn } from "~/utils/clsx";

const SupportCard = ({
  icon,
  title,
  textContent,
  buttonHref,
  buttonText,
  backgroundImage,
}: {
  icon: IconDefinition;
  title: string;
  textContent: string;
  buttonHref: string;
  buttonText: string;
  backgroundImage: "phone" | "chat" | "ticket";
}) => {
  return (
    <div
      className={cn(styles.card, {
        [styles.chatBackground]: backgroundImage === "chat",
        [styles.phoneBackground]: backgroundImage === "phone",
        [styles.ticketBackground]: backgroundImage === "ticket",
      })}
    >
      <div className={styles.cardTitle}>
        <Icon icon={icon} /> {title}
      </div>
      <div className={styles.cardTextContent}>{textContent}</div>
      <a href={buttonHref} target="_blank" rel="noreferrer">
        <Button state="neutral" className={styles.cardButton}>
          {buttonText}
        </Button>
      </a>
    </div>
  );
};

export default SupportCard;
