import styles from "./clusterListPage.module.css";

import CreateButton from "./components/CreateButton";
import ClusterTable from "./components/ClusterTable";
import PageBanner from "~/components/PageBanner";
import InDeploymentTable from "./components/InDeploymentTable";

const ClusterListPage = () => {
  return (
    <div className={styles.content}>
      <PageBanner
        title="Clusters"
        subTitle="Get an overview of your existing clusters, and create new ones"
        content={<CreateButton />}
        className={styles.banner}
      />

      <InDeploymentTable />
      <ClusterTable />
    </div>
  );
};

export default ClusterListPage;
