import useSWR from "swr";
import { Cluster, ClusterCredentials } from "~/types";

const useClusters = () => {
  return useSWR<Cluster[]>("clusters");
};

const useClusterCredentials = (shouldFetch: boolean, clusterName: string) => {
  return useSWR<ClusterCredentials>(
    shouldFetch ? `clusters/${clusterName}/credentials` : null,
  );
};

export { useClusters, useClusterCredentials };
