import { Icon, Input } from "@intility/bifrost-react";
import styles from "./nameStep.module.css";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { NewClusterForm } from "~/types";
import { isValidClusterName } from "~/utils/validations";

const NameStep = ({
  formData,
  setFormData,
}: {
  formData: NewClusterForm;
  setFormData: (formData: NewClusterForm) => void;
}) => {
  return (
    <div className={styles.content}>
      <div>
        <h4 className="bf-h4">Cluster name</h4>
        <span className="bf-label-description">
          Give your cluster a unique name that follows the criteria below
        </span>
      </div>

      <div className={styles.criteria}>
        <ul className={styles.criteriaList}>
          <li>
            <Icon icon={faArrowRight} />
            <span>
              A random string will be added to the name to ensure uniqueness.
            </span>
          </li>
          <li>
            <Icon icon={faArrowRight} />
            <span className={styles.charactersCriteria}>
              Only lowercase alphanumeric characters, meaning{" "}
              <span className={styles.allowedCharacter}>a</span> to{" "}
              <div className={styles.allowedCharacter}>
                <span>z</span>
              </div>
              ,{" "}
              <div className={styles.allowedCharacter}>
                <span>0</span>
              </div>{" "}
              to{" "}
              <div className={styles.allowedCharacter}>
                <span>9</span>
              </div>{" "}
              as well as{" "}
              <div className={styles.allowedCharacter}>
                <span>-</span>
              </div>{" "}
            </span>
          </li>
          <li>
            <Icon icon={faArrowRight} />
            <span>Must start and end with alphanumeric characters</span>
          </li>
          <li>
            <Icon icon={faArrowRight} />
            <span>Between 2 and 50 characters</span>
          </li>
        </ul>
      </div>
      <div className={styles.inputSection}>
        <Input
          label="Name"
          className={styles.inputWithValidation}
          placeholder="Cluster name"
          required
          maxLength={50}
          minLength={1}
          value={formData.clusterName}
          state={
            formData.clusterName?.length === 0
              ? undefined
              : formData.clusterName?.length > 0 &&
                  isValidClusterName(formData.clusterName)
                ? "success"
                : "alert"
          }
          feedback={
            formData.clusterName?.length === 0
              ? undefined
              : formData.clusterName?.length > 0 &&
                  isValidClusterName(formData.clusterName)
                ? undefined
                : "Your cluster name does not match the criteria"
          }
          onChange={(e) => {
            setFormData({
              ...formData,
              clusterName: e.target.value,
            });
          }}
        />
        <Input
          label="Your new cluster"
          className="bf-input-disabled"
          placeholder="Unique cluster name"
          readOnly
          value={
            formData.clusterName &&
            formData.clusterName + "-" + formData.clusterNameSuffix
          }
        />
      </div>
    </div>
  );
};

export default NameStep;
