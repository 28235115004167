import { Icon } from "@intility/bifrost-react";
import styles from "./createButton.module.css";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useRole from "~/hooks/useRole";

const CreateButton = () => {
  const navigate = useNavigate();
  const role = useRole();

  return (
    <>
      <button
        className={
          role.isAdmin ? styles.createButton : styles.createButtonHidden
        }
        onClick={() => navigate("/clusters/new")}
      >
        <Icon icon={faCirclePlus} fontSize={30} />
        <h5 className={`bf-h5 ${styles.buttonText}`}>Create new cluster</h5>
      </button>
    </>
  );
};

export default CreateButton;
